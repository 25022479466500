import React from 'react';
import './Contactus.css';
import location from '../../assets/images/location.png';
import envelop from '../../assets/images/envelop.png';
import mobilebutton from '../../assets/images/mobilebutton.png';
function Contactus() {
	return (
		<div className="content-wrapper">
			<div className="heading">
				<h2 className="m-auto">Contact Us</h2>
			</div>
			<div className="contactuswrapper">
				<div className="containers">
					<div className="heading-contact text-center">
						<label className="m-auto">
							We’d Love To <strong>Help You</strong>
						</label>
					</div>
					<div className="consumer-top  consumer-middle flex justify-center gap-100">
						<div className="contactus">
						<iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3500.4066859492514!2d77.20741637465083!3d28.67747878203974!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sGROUND%20FLOOR%20SHOP%20No%20-77%20PROPERTY%20NO%2C-17%20NEAR%20POWER%20HOUSE%20MALKA%20GANJ%20%2C%20NEW%20DELHI%2C%20NORTH%20DELHI%20110007%20!5e0!3m2!1sen!2sin!4v1698219948234!5m2!1sen!2sin" 
						width="100%"
						height="100%"
						allowfullscreen=""
						loading="lazy" 
						referrerpolicy="no-referrer-when-downgrade"
						title="map">
						</iframe>
							<div className="contactusdetail">
								<div className="contactaddress">
									<div className="address">
										<label>Registered Address</label>
									</div>
									<div className="address">
										<img src={location} alt="location" />
										<p>
											GROUND FLOOR SHOP No -77 PROPERTY NO,-17 <br />
											NEAR POWER HOUSE MALKA GANJ , NEW DELHI, NORTH DELHI 110007<br />
										</p>
									</div>
								</div>
								<div className="contactaddress">
									<div className="address">
										<label>Contact Us</label>
									</div>
									<div className="address">
										<img src={envelop} alt="envelop" />
										<p>
											<strong>Email Id :</strong>oofice.trinityenterprises@aol.com
										</p>
									</div>
									<div className="address">
										<img src={mobilebutton} alt="mobilebutton" />
										<p>
											<strong>Phone No :</strong>7710650787
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Contactus;
