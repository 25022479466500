import React from 'react';
import './GrowYourBussion.css';
import user from '../../assets/images/user-dil.svg';
import bag from '../../assets/images/badge-check.svg';
import shield from '../../assets/images/shield-check.svg';
import smile from '../../assets/images/smile.svg';
import mobile from '../../assets/images/mobile-1.svg';
import earnYou from '../../assets/images/earnYou.svg'
import bagtick from '../../assets/images/bagtick.svg'
import friendRegister from '../../assets/images/friendRegister.svg'
import referFriend from '../../assets/images/friendRefer.svg'

const GrowYourBussion = () => {
	return (
		<div className="GrowYourBussionWrapper">
			<div className="container">
				<div className="GrowYourBussion-wrapper ">
					<div className='align'>
						<div className="middle-wrapper">
							<h2 className="heading-2 text-center">
								Let your friends pay their bills<span className="strong"> <br />and make money</span>
							</h2>
							<div className="center gap-60">
								<div className="main-boxes">
									<div className='boxes flex'>
										<img src={earnYou}></img>
										<div>
											<h2 className='boxes-h2'>Earn You</h2>
											<p className='boxes-h2 fs-13'>You get ₹20. You can use these credits to take recharge.</p>
										</div>

									</div>

								</div>
								<div className="main-boxes">
									<div className='boxes flex'>
										<img src={friendRegister}></img>
										<div>
											<h2 className='boxes-h2'>Your Friends Register</h2>
											<p className='boxes-h2 fs-13'>Your friends Register with using your referral link.</p>
										</div>

									</div>
								</div>
								<div className="main-boxes">
									<div className='boxes flex'>
										<img src={referFriend}></img>
										<div>
											<h2 className='boxes-h2'>You Refer Friends</h2>
											<p className='boxes-h2 fs-13'>Share your referral link with friends. They get ₹10.</p>
										</div>

									</div>

								</div>
							</div>
						</div>

						<div className="middle-wrapper">
							<h2 className="heading-2 text-center">
								Get To Know <span className="strong">Bharat Enterprises!</span>
							</h2>
							<div className="center gap-30">
								<div className="box items-center">
								<img src={user}></img>
									<h2 className='text'>Trust pay</h2>
									<p className='text'>100% Payment Protection. Easy Return Policy.</p>
								</div>

							<div className="box items-center">
								<img src={bagtick}></img>
									<h2 className='text'>Fast Bill Pay</h2>
									<p className='text'>Pay Fast Be Smart</p>
								</div>
								<div className="box  items-center">
									<img src={shield}></img>
									<h2 className='text'>Secure Payments</h2>
									<p className='text'>Moving your card details to a much more secured place.</p>

								</div>
								<div className="box items-center">
									<img src={smile}></img>
									<h2 className='text'>4X7 Support</h2>
									<p className='text'>We're here to help. Have a query and need help ? Click here</p>

								</div>

							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	);
};

export default GrowYourBussion;
