import styled from 'styled-components';

export const Wrapper = styled.div`
	/* background-color: var(--header-bg_color); */
	.header-balance {
		padding-top: 20px;
		padding-bottom: 0 !important;
		display: none !important;

		div.main-balance {
			border-right: none;
		}
	}
	.balance {
		margin-right: 10px;
		& > div {
			padding: 0 20px;
			border-right: 2px dotted gray;
			.heading {
				font-size: 12px;
				color: var(--balance-heading);
			}
			.value {
				font-size: 17px;
				color: var(--balance-value);
			}
		}
		.img {
			margin-right: 4px;
		}
	}
	.top-header,
	.header-balance {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 30px 0 20px;
		position: relative;

		.header-link {
			margin-right: 10px;
			ul {
				display: flex;
				justify-content: flex-end;
				gap: 20px;
				align-items: center;
				li {
					list-style: none;
					a,
					.nav-more {
						color: var(--header-color);
						font-weight: 600;
						font-size: 13px;
						line-height: 20px;
						/* text-transform: capitalize; */
						padding-bottom: 10px;
						transition: color 0.3s;
						position: relative;
						&:hover {
							color: var(--header-color_hover);
						}
						&.active {
							border-bottom: 2px solid var(--header-color_hover);
							color: var(--header-color_hover);
						}
					}
					.nav-more {
						padding: inherit;
						&:hover .profile-dropdown {
							height: auto;
							padding: 10px 0;
							overflow: visible !important;
						}
					}
					.profile-dropdown {
						a {
							display: block;
							width: 100%;
							padding: 4px 20px;
							font-weight: 400;
							color: var(--profile-dropdown_color);
							border-bottom: 0 solid transparent;
							&:hover,
							&.active {
								color: var(--profile-dropdown_color_hover);
								background-color: var(--profile-dropdown_bg_hover);
								border-bottom: 0 solid transparent;
							}
						}
						ul {
							display: block;
						}
					}
				}
			}
		}
		.header-buttons {
			color: var(--white);
			display: flex;
			align-items: center;
			gap: 10px;
			& > .name {
				gap: 5px;
				align-items: center;
				.profile {
					position: relative;
					display: flex;
					align-items: center;
					gap: 10px;
					.logoName {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 40px;
						height: 40px;
						border-radius: 50%;
						background: var(--logoName-bgColor);
						.profile-text {
							text-transform: uppercase;
							letter-spacing: 2px;
							font-weight: 600;
						}
						img {
							width: 100%;
							height: auto;
						}
					}

					&:hover .profile-dropdown {
						font-size: 13px;
						font-weight: 400;
						text-transform: capitalize;
						height: auto;
						padding: 10px 0;
						overflow: visible !important;
					}
				}
			}
			.login {
				color: var(--login-btn-color);
				font-weight: bold;
				font-size: 13px;
				border: none;
				line-height: 20px;
				background-color: var(--login-btn-bg);
				padding: 10px 20px;
				border-radius: 5px;
				cursor: pointer;
			}
			.login-img{
				height: 12px;
				width: 12px;
				margin: 3px 3px 0px 0px;
			}
			.register {
				color: #fff;
				font-size: 13px;
				font-weight: bold;
				line-height: 20px;
				//border: 1px solid #00baf2;
				border: 0;
				//padding: 10px 20px;
				background: transparent;
				cursor: pointer;
			}
			
		}
		.profile-dropdown {
			position: absolute;
			right: 0px;
			/* transform: translateX(-50%); */
			width: 100%;
			min-width: 200px;
			top: calc(100% + 2px);
			width: 183px;
			font-weight: 400;
			font-size: 13px;
			background: #ffffff 0% 0% no-repeat padding-box;
			box-shadow: 0px 1px 99px #0d143c;
			height: 0px;
			overflow: hidden;
			z-index: 10;
			border-radius: 5px;
			transition: height 0.3s;
			&::after {
				content: '';
				position: absolute;
				top: -10px;
				right: 30px;
				margin-left: -5px;
				border-width: 5px;
				border-style: solid;
				//border-color: #fff transparent transparent transparent;
				border-color: transparent transparent #fff transparent;
				z-index: 10;
			}

			a,
			span {
				display: block;
				width: 100%;
				text-align: left;
				border: none;
				background-color: transparent;
				font-weight: 400;
				font-size: 13px;
				color: #000000;
				padding: 4px 20px;
				transition: all 0.3s;
				cursor: pointer;
				&:hover,
				&.active {
					color: var(--profile-dropdown_color_hover);
					background-color: var(--profile-dropdown_bg_hover);
				}
			}
		}
	}
	.header-bottom {
		color: #fff;
		margin-bottom: 8px;
		font-size: 13px;
		color: #00baf2;
		.head {
			font-weight: 600;
			margin-right: 10px;
		}
	}

	@media only screen and (max-width: 1116px) {
		.d-s-none {
			display: none !important;
		}
		.header-balance {
			display: flex !important;
		}
	}
	@media only screen and (max-width: 767px) {
		.top-header {
			padding: 15px;
			.dropdown_li {
				display: none;
			}

			.toggle-icon {
				.spinner {
					width: 20px;
					background-color: #fff;
					height: 2px;
					transition: all 0.32s cubic-bezier(0.4, 0, 0.6, 1);

					&:first-child {
						margin-bottom: 6px;
					}
					/* &:last-child {
						margin:6px 0 0 ;
					} */
				}
				&.active .spinner {
					&:first-child {
						margin-bottom: 1px;
						transform: rotate(145deg);
					}
					&:last-child {
						margin-top: -3px;
						transform: rotate(-135deg);
					}
				}
			}

			.header-link {
				position: absolute;
				top: calc(100% - 2px);
				left: -20px;
				width: 100vw;
				z-index: 100;
				height: 0;
				background-color: var(--header-bg_color);
				overflow: hidden;
				transition: height 0.32s cubic-bezier(0.4, 0, 0.6, 1);
				&.active {
					height: calc(100vh - 28px);
				}

				ul {
					display: block;
					padding: 12px 0;
					li {
						padding: 12px 0;
						a {
							font-size: 20px;
							padding: 4px 28px;
						}
						a.active {
							border-bottom: none;
						}
					}
				}
			}
		}
	}
	@media only screen and (max-width: 500px) {
		.top-header .header-buttons > .name {
			display: block;
			margin-right: 5px;
		}
		.top-header {
			.logo {
				img {
					width: 90px;
				}
			}
		}
	}
`;
