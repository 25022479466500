import user from '../../assets/images/user-dil-red.svg';
import star from '../../assets/images/start.svg';
import bag from '../../assets/images/bag.svg';

function Component() {
	return (
	<>
	</>
		
	);
}

export default Component;
