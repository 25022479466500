import styled from 'styled-components';

export const Wrapper = styled.div`
	//background-color: var(--header-bg_color);

	.header-nav {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		gap: 4px;
		.nav-link {
			flex: 1;
			height: 70px;
			background-color: var(--tab-link-bgColor);
			font-size: 13px;
			line-height: 20px;
			font-weight: 500;
			display: flex;
			align-items: center;
			justify-content: end;
			flex-direction: column;
			padding-bottom: 12px;
			border-radius: 10px;
			margin: 35px 0px 0px 0px;
			cursor: pointer;
			&:hover,
			&.active {
				background-color: var(--tab-link-bgColor-hover);
				color: var(--tab-link-color-hover);
				border-bottom: 1px solid #dfdfdf;
				/* svg {
					.path-stroke {
						stroke: #002970;
					}
					.path-color {
						fill: #002970;
					}
					.path-stroke-white {
						stroke: #fff;
					}
					#Path_197,
					#Line_2,
					#Line_5,
					
					#Ellipse_42{
						stroke: #002970;
					}
				
				
				
			} */
			
			/* &.active .pic,
			&:hover .pic{
				svg {
					#Subtraction_4,
					#Path_550{
						stroke:#002970
					}
				}
			} */
		}
	} 
	}
	.slick-slider {
		width: calc(100% + 8px); 
		margin-left: -4px;
	}
	
	.slick-track{
		display: flex;
		gap: 5px;
		margin-left: 0px;
	}

	.slick-prev, .slick-next{
		width: 40px;
		height: 40px;
		z-index: 1;
		
	}

	.slick-next {
    right: 2px;
}
.slick-prev {
    left: 2px;
 
}


.slick-dots li button:before{
	color: var(--slick-dots-color);
	opacity: 1;
}
.slick-dots li.slick-active button:before{
	color: var(--slick-active-color);
}
	.slick-prev:before, .slick-next:before {
  
    color: var(--slick-active-color);
	font-size: 25px;
    
}
`;
