import IconApartment from '../../assets/images/Apartment.svg';
import IconPipedGas from '../../assets/images/pipedGas.svg';
import IconBookGas from '../../assets/images/BookGas.svg';
import IconEMI from '../../assets/images/EMI.svg';
import IconCabletv from '../../assets/images/Cabletv.svg';
import IconOTT from '../../assets/images/OTT.svg';
import IconMetro from '../../assets/images/Metro.svg';
import IconLandline from '../../assets/images/landline.svg';
import IconInsurance from '../../assets/images/Insurance.svg';
import IconFastag from '../../assets/images/Fastag.png';
import IconWater from '../../assets/images/waterIcon.svg';

export const subTabs = [
	{
		title: 'Recharge & Bill Payment',
		services: [
			{
				title: 'Piped Gas',
				id: 'subTab1',
				icon: IconPipedGas,
			},
			{
				title: 'Landline',
				id: 'subTab2',
				icon: IconLandline,
			},

			{
				title: 'OTT',
				id: 'subTab5',
				icon: IconOTT,
			},
			{
				title: 'Gas Cylinder',
				id: 'subTab6',
				icon: IconBookGas,
			},
			{
				title: 'Fastag',
				id: 'subTab7',
				icon: IconFastag,
			},
			{
				title: 'Metro',
				id: 'subTab8',
				icon: IconMetro,
			},
			{
				title: 'Cable TV',
				id: 'subTab9',
				icon: IconCabletv,
			},
			{
				title: 'Apartment',
				id: 'subTab10',
				icon: IconApartment,
			},
			{
				title: 'Water',
				id: 'subTab11',
				icon: IconWater,
			},
		],
	},
	{
		title: 'Financial Services',
		services: [
			{
				title: 'EMI',
				id: 'subTab4',
				icon: IconEMI,
			},
			{
				title: 'Insurance',
				id: 'subTab3',
				icon: IconInsurance,
			},
		],
	},
];
