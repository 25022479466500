import styled from 'styled-components';

export const Wrapper = styled.div`

	background-image: url('../../assets/images/banner-image.png');
	background-position: bottom left;
	background-repeat: no-repeat;
     /* background-color: var(--tab-content-bgColor); */
	margin: 0px -30px 0px -30px;
	
	 height: 318px;
	 border-radius: 10px;


	.banner-text {
		padding: 50px 0 20px 0px;
		position: absolute;
		

		h1 {
			font-size: 30px;
			line-height: 42px;
			font-weight: bold;
			color: #141414;
			text-transform: uppercase;
		}
		.flat-text {
			font-size: 15px;
			line-height: 39px;
			color: #141414;
			
			opacity: 0.5;
			margin-bottom: 13px;
		}
		i {
			margin-top: 82px;
			display: block;
			font-style: normal;
			font-size: 10px;
			line-height: 16px;
		}
	}
	.bannaer-data{
		background-color: red;
		border-radius: 10px 0px 0px 0px;
		margin-top: 30px;
		border: 1px solid blue;
	}

	.bannerwrapper1{
	margin: -252px 0px 0px 0px;
	
}
.bannerwrapper1 img{
	margin-top: -80px;
}
.bannerwrapper1 .right-text{
	margin-bottom: 20px;
}
.bannerwrapper1 .right-text span{
	display: block;
}
.bannerwrapper1 .right-text h2{
color: #071926;
}
.bannerwrapper1 .right-text p{
	color:#071926;
}
.bannerwrapper1 button{
		width: 144px;
		height: 45px;
		border-radius: 5px;
		border: none;
		background-color: #FCB240;
		color: #F6F3EA;
	}
.bannerwrapper2{
	margin: -252px 0px 0px 0px;
	
}
.bannerwrapper2 .right-text{
	margin-bottom: 20px;
}
.bannerwrapper2 .right-text span{
	display: block;
}
.bannerwrapper2 .right-text h2{
color: #FFFFFF;
}
.bannerwrapper2 .right-text p{
	color: #FFFFFF;
}
.bannerwrapper2 button{
		width: 144px;
		height: 45px;
		border-radius: 5px;
		border: none;
		background-color: #FCB240;
		color: #F6F3EA;
	}
`;
