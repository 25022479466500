import React from 'react';
import { Wrapper } from './style';
import img from '../../assets/images/banner-image.png'
import bgbanner1 from '../../assets/images/banner-bg-1.png'
import bgbannermobile2 from '../../assets/images/banner-2-mobile.png'
import bgbanner2 from '../../assets/images/banner-bg-2.png'
import bgbannermobile from '../../assets/images/bg-banner-2-mobile.png'


export default function OfferBanner() {
	return (
		<>
			<Wrapper className='d-m-none'>
			<div className="banner-text">
			<div className='bannerWrapper flex gap-20'>
					<div className=''>
						<img src={bgbanner1}></img>
						<div className='bannerwrapper1 flex'>
							<div className='left'>
								<img src={bgbannermobile2}></img>
							</div>
							<div className='right'>
								<div className='right-text'>
									<h2 className='fs30'>Win Cashback <span>Every Day</span></h2>
									<p className='fs15'>The best B2B and Banking <span>services provider</span></p>
								</div>

								<button>join us</button>
							</div>
						</div>
					</div>
					<div className=''>
						<img src={bgbanner2}>

						</img>
						<div className='bannerwrapper2 flex'>
							<div className='left'>
								<img src={bgbannermobile}></img>
							</div>
							<div className='right'>
								<div className='right-text'>
									<h2 className='fs30'>Win Cashback <span>Every Day</span></h2>
									<p className='fs15'>The best B2B and Banking <span>services provider</span></p>
								</div>

								<button>join us</button>
							</div>
						</div>
					</div>
				</div>			
			</div>
			
		</Wrapper>
	
		</>
	
	);
}